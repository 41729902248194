.tooltip {
  scale: 1 !important;
  margin-left: 0.2em;
  color: rgba(0, 0, 0, 0.5);
}
.tooltip:hover {
  color: black;
  background-color: #f4f4f6;
  cursor: pointer;
}

::ng-deep .ant-tooltip-inner {
  /* background-color: rgba(0, 0, 0, 0.4); */
  border-radius: 5px !important;
}

::ng-deep .ant-tooltip-arrow {
  display: none;
}
