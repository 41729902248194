#divChart {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}

.chart-container {
  border-radius: 8px; /* Adjust the border radius as needed */

  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  overflow: hidden; /* Hide overflow if necessary */
  padding: 10px;
  background-color: white;
}

#mohDmsw {
  width: 100%; /* Make sure the canvas fills its container */
  height: 30vh;
}
