.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}

nz-layout {
  background: #f4f4f6;
}

.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}

::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}

::ng-deep .ant-btn:focus {
  color: black;
}

::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}

::ng-deep .material-icons {
  font-size: 22px;
}

::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}

.title {
  padding-left: 1em;
  padding-right: 1em;
}
.add-button {
  margin-top: 1em;
  padding-right: 1em;
}

.table-header-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-llr {
  margin-left: 2em;
  margin-right: 1em;
}

.margin-lr {
  margin-left: 1em;
  margin-right: 1em;
}

.margin-lrr {
  margin-left: 1em;
  margin-right: 0.5em;
}

::ng-deep nz-switch {
  margin-right: 0.5em;
}

.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.no-known-content {
  display: flex !important;
}

.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}

::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}

::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}

::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}

.add-icon {
  margin-right: 0.2em;
}

@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}

.draft-entry td:first-child {
  border-left: 2px solid #0d9488; /* Replace with your desired color and width */
}

.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}

.delete-btn {
  cursor: pointer;
}

.expandable-row {
  cursor: pointer;
}

tr:hover {
  cursor: pointer;
}

.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.no-known-content {
  display: flex !important;
}

.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}

::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}

::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}

::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}

.add-icon {
  margin-right: 0.2em;
}

@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}

.document-view {
  border: 1px solid lightgray;
  /* border-radius: 10px; */
  min-width: 100% !important;
}

.row {
  height: 100%;
}

.pdf-viewer {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.preview-content {
  height: 60vh;
  width: 100%;
}

.preview-title {
  font-size: 18px;
  font-weight: 500;
  padding: 1em;
  height: 64px;
  border-bottom: 1px solid lightgray;
  background: rgba(118, 195, 188, 0.15) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-btn:hover {
  cursor: pointer;
}

.anticon {
  display: block;
  margin: 1em;
}

.folder-view-icon.anticon {
  scale: 2.5 !important;
}

.preview {
  margin-bottom: 2em;
}
.image-viewer {
  max-width: 100%;
  max-height: 95%;
  width: auto;
  height: auto;
  display: block;
  cursor: pointer;
}
.video-viewer {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
}
::ng-deep .ant-image-preview-operations {
  background: #0d9488 !important;
  /* line-height: 3 !important; */
}

::ng-deep .ant-image-preview-mask {
  background-color: rgba(50, 50, 50, 0.9) !important;
}

/* .modal-video {
  min-height: 70vh !important;
  min-width: 100% !important;
} */

.selected-preview {
  background-color: rgba(118, 195, 188, 0.1) !important;
}
