.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}

nz-layout {
  background: #f4f4f6;
}

.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}

::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}

::ng-deep .ant-btn:focus {
  color: black;
}

::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}

::ng-deep .material-icons {
  font-size: 22px;
}

::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}

.title {
  padding-left: 1em;
  padding-right: 1em;
}

.add-button {
  margin-top: 1em;
  padding-right: 1em;
}

.table-header-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-llr {
  margin-left: 2em;
  margin-right: 1em;
}

.margin-lr {
  margin-left: 1em;
  margin-right: 1em;
}

.margin-lrr {
  margin-left: 1em;
  margin-right: 0.5em;
}

::ng-deep nz-switch {
  margin-right: 0.5em;
}

.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.no-known-content {
  display: flex !important;
}

.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}

::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}

::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}

::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}

.table-margin {
  margin-bottom: 2em;
}

.add-icon {
  margin-right: 0.2em;
}

@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}

.draft-entry {
  border-right: 2px solid #0d9488;
  border-top: 2px solid #0d9488;
  border-bottom: 2px solid #0d9488;
}

.first-draft-entry {
  border-left: 2px solid #0d9488;
}

.edit-btn {
  margin-left: 1em;
  cursor: pointer;
}

.delete-btn {
  cursor: pointer;
}

.expandable-row {
  cursor: pointer;
}

.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.no-known-content {
  display: flex !important;
}

.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}

::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}

::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}

::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}

.add-icon {
  margin-right: 0.2em;
}

@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.bold {
  font-weight: 500;
}

.actions {
  margin-left: 1em;
  margin-right: 1em;
  float: right;
}
