#divChart {
  display: flex !important;
  width: 100% !important;
  height: 40vh;
}

.chart-container {
  border-radius: 8px; /* Adjust the border radius as needed */

  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  overflow: hidden; /* Hide overflow if necessary */
  padding: 10px;
  background-color: white;
}

#admissionReason {
  width: 100%; /* Make sure the canvas fills its container */
  height: 40vh;
}
