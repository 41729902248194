.titleBar {
  display: flex;
  align-items: center; /* Center items vertically */
}

.titleBar::before {
  content: ""; /* Add content to the pseudo-element */
  width: 5px; /* Set the width of the bar */
  height: 30px; /* Set the height of the bar */
  background-color: #0d9488; /* Set the color of the bar */
  margin-right: 10px; /* Add some space between the bar and the text */
}

.new-user-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.new-user-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.required-icon {
  color: red;
  font-size: 1.2em;
  margin-left: 5px;
}

.add-patient-button,
.back-button {
  height: 35px;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-patient-body {
  /* border: 1px solid green; */
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}

/* .add-patient-row {
  margin-bottom: 1.5em;
} */

.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
  height: 35px !important;
}

.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}

select.ng-valid.ng-touched {
  color: black;
}

input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

input[type="date"] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

::placeholder {
  color: #c2c2c2;
}

.add-patient-button:hover {
  cursor: pointer;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

.submitted {
  .ng-invalid {
    border: red 1px solid;
    border-radius: 2px;
  }
}

.add-patient-button:hover,
.back-button:hover {
  cursor: pointer;
}

.add-patient-input {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mat-select {
  width: 100%;
  font-family: "Inter", sans-serif !important; /* Use your desired font family */
}

.mat-input-element {
  font-family: "Inter", sans-serif !important; /* Use your desired font family */
}

/* Adjust the styles for two dropdowns in a row */
@media (min-width: 600px) {
  .mat-form-field {
    width: calc(50% - 8px);
    margin-right: 16px;
  }
}

/* Custom icon for the stepper */
::ng-deep .mat-step-icon-selected,
::ng-deep .mat-step-icon-state-edit {
  background-color: #0d9488 !important; /* Change the color as needed */
  color: white !important;
}

/* Styling for the dropdown arrow */
::ng-deep .mat-select-arrow {
  color: #317d88 !important; /* Change the color as needed */
}
::ng-deep.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 30px !important;
}
::ng-deep.mat-mdc-form-field {
  gap: 5px !important;
}

/* Styling for the focused state of input and dropdown */
::ng-deep .mat-focused .mat-form-field-label {
  color: #317d88 !important; /* Change the color as needed */
}

::ng-deep .mat-focused .mat-form-field-underline {
  background-color: #317d88 !important; /* Change the color as needed */
}

/* Adjust the styles for two dropdowns in a row */
@media (min-width: 600px) {
  .mat-form-field {
    width: calc(50% - 8px);
    margin-right: 16px;
  }
}

.row-fields {
  margin-bottom: 80px;
  width: 600px;
}
/*.top-field*/
/*{*/
/*  display: flex;*/
/*}*/
/*::ng-deep.mat-mdc-form-field*/
/*{*/
/*  color: #0d9488;*/
/*  background-color: #161d20;*/
/*}*/

/* Add this to your existing styles or create a new CSS file */
::ng-deep .mat-mdc-form-field {
  padding-left: 20px;
  margin-bottom: 0px !important;
  background-color: white;
}
::ng-deep .mat-mdc-text-field {
}
::ng-deep .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  border-radius: 10px;
  color: #161d20;
  background-color: white !important;
}

.btn-margin {
  margin-left: 20px;
}

::ng-deep .mat-vertical-content {
  margin: 10px 10px 10px 10px;
}
/*mat-select {*/
/*  width: 100%;*/
/*  font-family: "Inter", sans-serif !important; !* Use your desired font family *!*/
/*  border: 1px solid #ccc; !* Add border around the dropdown *!*/
/*  border-radius: 4px; !* Add border radius for a rounded appearance *!*/
/*  overflow: hidden; !* Hide overflow to prevent shadow from spilling out *!*/
/*}*/

/*.mat-select-trigger {*/
/*  padding: 8px; !* Add padding to the trigger button *!*/
/*}*/

/*.mat-input-element {*/
/*  font-family: "Inter", sans-serif !important; !* Use your desired font family *!*/
/*}*/

/* Adjust the styles for two dropdowns in a row */
@media (min-width: 600px) {
  .mat-form-field {
    width: calc(50% - 8px);
    margin-right: 16px;
  }
}

/* Custom icon for the stepper */
::ng-deep .mat-step-icon-selected,
::ng-deep .mat-step-icon-state-edit {
  background-color: #0d9488 !important; /* Change the color as needed */
  color: white !important;
}

/* Styling for the dropdown arrow */
::ng-deep .mat-select-arrow {
  color: #317d88 !important; /* Change the color as needed */
}

/* Styling for the focused state of input and dropdown */
::ng-deep .mat-focused .mat-form-field-label {
  color: #636363 !important; /* Change the color as needed */
}

::ng-deep .mat-focused .mat-form-field-underline {
  background-color: #317d88 !important; /* Change the color as needed */
}
.disabled-button {
  background-color: #a0a0a0; /* Change the color as needed for the disabled state */
  border: 1px solid #a0a0a0; /* Change the border color as needed for the disabled state */
  cursor: not-allowed;
}

.disabled-button:hover {
  background-color: #a0a0a0; /* Change the color as needed for the disabled state */
  border: 1px solid #a0a0a0; /* Change the border color as needed for the disabled state */
  cursor: not-allowed;
}

/*Loading*/
::ng-deep.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #0d9488 !important;
}

.cancel-button {
  height: 35px;
  border: 1px solid #ffd470;
  border-radius: 4px;
  background-color: #ffd470;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.loading-text {
  margin-top: 10px;
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Inter", sans-serif !important;
}

::ng-deep.mat-vertical-stepper-header {
  pointer-events: none !important;
}

::ng-deep .ant-select-selector {
  width: 100% !important;
  color: black !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  min-height: 35px !important;
}

.multiselector {
  margin-left: 1em !important;
  margin-right: 1em !important;
}
.required-asterisk {
  color: red;
  margin-left: 0.25rem; /* Adds a small space between label and asterisk */
}
::ng-deep .mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mdc-circular-progress__determinate-circle {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: rgb(13, 148, 136) !important;
}
/*****************************************************
	Custom styling for intl tel input.
*****************************************************/

::ng-deep ngx-intl-tel-input input {
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

::ng-deep ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

::ng-deep ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

::ng-deep ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

::ng-deep ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
/* General error message style */
.error-message {
  display: block; /* Ensure error messages are block elements */
  color: red; /* Red for errors */
  font-size: 12px; /* Smaller font size */
  margin-top: 2px; /* Reduced space between input and error */
}

/* Password Criteria */
.password-criteria div {
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  align-items: center; /* Align the text and icons properly */
}

/* Validation Icons */
.valid {
  color: green; /* Green for valid criteria */
}

.invalid {
  color: red; /* Red for invalid criteria */
}

/* Adjusting icon size */
mat-icon {
  font-size: 16px; /* Smaller icon size */
  vertical-align: middle;
}
.info-icon {
  margin-left: 5px; /* Add some space between the label and the icon */
}
/* Custom error border for nz-input-group */
.error-border {
  border: 1px solid red !important;
  border-radius: 4px;
}
.passwordInput {
  border: none !important;
  border-radius: 0 !important;
}
.password-row {
  margin-bottom: 12px;
}
::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
