.patients-header-body {
  display: flex;
  position: fixed;
  background-color: white;
  min-height: 120px !important;
  max-height: 120px !important;
  width: 100%;
  z-index: 1;
  padding: 15px 30px;
  font-size: 13px;
  border-bottom: 2px solid rgba(118, 195, 188, 0.2) !important;
}

.inpatients-header-body {
  display: flex;
  position: fixed;
  background-color: white;
  min-height: 120px !important;
  max-height: 120px !important;
  width: 100%;
  z-index: 1;
  padding: 15px 30px;
  font-size: 13px;
  border-bottom: 2px solid rgba(118, 195, 188, 0.2) !important;
}

.header-patients {
  cursor: pointer;
  color: #161d20;
  font-size: 14px;
  font-weight: 500;
  margin-right: 0.5em;
}

.header-patients:hover {
  cursor: pointer;
  color: #0d9488;
  font-size: 14px;
  text-decoration: underline;
  margin-right: 0.5em;
}

.header-details {
  display: flex;
}

.header-details-2 {
  margin-top: 0.5em;
  display: flex;
}

.divider {
  margin-left: 5px;
  margin-right: 5px;
  color: #d8d8d8;
}

.split-lines {
  display: block !important;
}

.add-episode {
  height: 35px;
  border: 1px solid #0d9488 !important;
  border-radius: 4px;
  background: #0d9488 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-episode:hover {
  cursor: pointer;
}

.fullname {
  font-size: 17px;
  color: #161d20;
  font-weight: 600;
}

.information-icon {
  margin-left: 0.3em;
  color: #76c3bc;
  margin-top: auto;
  margin-bottom: auto;
  /* scale: 0.8; */
}

.information-icon:hover {
  cursor: pointer;
  color: #0d9488;
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}

.discard-button {
  border-radius: 5px;
  border: 1px solid red !important;
  background: #fcf0f0 !important;
}

.discard-button:hover {
  background: #fcd6d6 !important;
}

.preview-button {
  border-radius: 5px;
  margin-left: 1em;
  background: rgb(180 235 230 / 40%) !important;
}

.back-button {
  border-radius: 5px;
  border: 1px solid orange !important;
  background: #fdf3db !important;
}

.back-button:hover {
  background: #ffd470 !important;
}

.save-button {
  border-radius: 5px;
  margin-left: 1em;
  border: 1px solid #0d9488 !important;
  background: #caefed !important;
}

.save-button-hover:hover {
  background: #53c9c1 !important;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-pp {
  padding-right: 20px;
}

.profilePicture {
  border: 1px solid #4bc0b2;
}

.w-100 {
  width: 100%;
}

@media only screen and (max-width: 1279.99px) {
  .btn-text {
    display: none !important;
  }
}

.header-icon {
  scale: 1 !important;
}
