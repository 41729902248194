.form-spacing {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}

form {
  padding-top: 0px;
}

.input-row {
  padding-top: 20px !important;
  display: flex !important;
  align-items: center !important;
  width: 100%;
}

.input-row-2 {
  display: flex !important;
  align-items: center !important;
  width: 100%;
}

.save-btn{
  margin-right: 1em;
}
.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}

.cancel-btn:hover {
  background: lightgray !important;
}


.input-element {
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.label-row {
  flex: 0 0 20% !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 5px !important;
}

.optional {
  font-weight: 300 !important;
  font-size: 12px !important;
  display: flex;
  flex-direction: column;
}

.select {
  width: 100% !important;
  margin-left: 1em !important;
}

.text-area {
  width: calc(100% - 1em) !important;
  margin-left: 1em !important;
  border: 1px solid #d9d9d9 !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 4px !important;
}

.btn-row {
  margin-top: 20px !important;
  display: flex !important;
  justify-content: flex-end !important;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

::ng-deep nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}

.item-submitted {
  .item.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}


::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}

::ng-deep .ant-btn:focus {
  color: black;
}

::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
