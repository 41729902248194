.app-body {
  height: 100vh;
  width: 100%;
}

/* ::ng-deep .swal2-popup ::ng-deep .swal2-styled:focus {
  box-shadow: none !important;
}

::ng-deep div:where(.swal2-container) ::ng-deep div:where(.swal2-popup) {
  border-radius: 10px !important;
}

::ng-deep
  div:where(.swal2-container)
  ::ng-deep
  div:where(.swal2-html-container) {
  font-size: 20px !important;
  margin: 1.5em 1.5em 1.5em !important;
}

::ng-deep div:where(.swal2-container) ::ng-deep div:where(.swal2-actions) {
  margin: 0px !important;
} */
