.ehr-body {
  width: 100%;
}

.container {
  width: 100%;
  height: 100%;
  /* margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  height: calc(90vh - 40px);
  width: calc(100vw - 60px);
  border: 1px solid red; */
}
