.ant-layout-header {
    font-weight: 600;
    font-size: 20px;
    background-color: #f4f4f6;
    background: #f4f4f6;
  }
  
  nz-layout {
    background: #f4f4f6;
    height: 100%;
  }
  
  nz-content {
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .capnography {
    /* border: 1px solid red; */
  }
  
  .content {
    width: 100%;
  }
  