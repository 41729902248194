#canvas {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: unset !important;
}

.chart {
  padding: 1em;
  border: 1px solid #d8d8d8;
  background: white;
}
