/* Container Styles */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}

.add-patient-row {
  margin-bottom: 1.5em;
}

.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
}

/* Input Styles */
.add-patient-input,
.textarea-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  box-sizing: border-box;
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))))
    calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  /* line-height: calc(
    1.5 *
      (
        var(
          --sjs-internal-font-editorfont-size,
          var(--sjs-font-editorfont-size, var(--sjs-font-size, 16px))
        )
      )
  ); */
  /* font-family: var(
    --sjs-font-editorfont-family,
    var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)))
  ); */
  /* font-weight: var(--sjs-font-editorfont-weight, 400);
  color: var(
    --sjs-font-editorfont-color,
    var(--sjs-general-forecolor, rgba(0, 0, 0, 0.91))
  ); */
  /* font-size: var(
    --sjs-internal-font-editorfont-size,
    var(--sjs-font-editorfont-size, var(--sjs-font-size, 16px))
  ); */
  background-color: var(
    --sjs-editorpanel-backcolor,
    var(
      --sjs-editor-background,
      var(
        --sjs-general-backcolor-dim-light,
        var(--background-dim-light, #f9f9f9)
      )
    )
  );
  border: none;
  border-radius: var(
    --sjs-editorpanel-cornerRadius,
    var(--sjs-corner-radius, 4px)
  );
  text-align: start;
  box-shadow: var(--sjs-shadow-inner, inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)),
    0 0 0 0px var(--sjs-primary-backcolor, var(--primary, #19b394));
  transition: box-shadow var(--sjs-transition-duration, 150ms);
}

.add-patient-input:focus,
.textarea-input:focus {
  box-shadow: var(
      --sjs-shadow-inner-focus,
      inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)
    ),
    0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #0d9488));
  outline: none;
}

/* Resize textarea */
.textarea-input {
  min-height: 100px;
  resize: vertical;
  overflow: auto;
}

.add-patient-inputZorro {
  width: 100%;
}

/* Validation Styles */
.nz-select.ng-invalid.ng-touched,
input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}

.nz-select.ng-valid.ng-touched,
input.ng-valid.ng-touched,
select.ng-valid.ng-touched,
textarea.ng-valid.ng-touched {
  color: black;
}

input,
textarea {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

input[type="date"] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

/* Placeholder Styles */
::placeholder {
  color: transparent;
}

/* Button Styles */
.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-patient-button:hover {
  cursor: pointer;
}

.back-button {
  margin-right: 1em;
  margin-bottom: 2em;
  height: 35px;
  border: 1px solid #ff2626;
  border-radius: 4px;
  background-color: white;
  color: #ff2626;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Optional Label Styles */
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}

/* Submitted Form Styles */
.submitted .ng-invalid {
  border: red 1px solid;
}

/* Radio Button List Styles */
.radio-button-list label {
  display: block;
  padding: 5px 0;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.radio-label input {
  margin-right: 8px;
}

input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}

input[type="radio"]:checked {
  background-color: #0d9488;
  border: 2px solid #0d9488;
}

input[type="radio"]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.title {
  margin: 12px 0px 12px 0px !important;
  font-size: 12pt !important;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif !important;
  letter-spacing: 0 !important;
}
.add-patient-input,
.textarea-input {
  font-family: Roboto, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0.2px !important;
  line-height: 20px !important;
  color: rgb(32, 33, 36) !important;
}
/* Ensure the label and radio input are vertically centered */
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.radio-label input {
  margin-right: 8px;
  vertical-align: middle; /* Ensures that the radio button aligns with the text */
}

/* Radio buttons */
input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  display: inline-block;
  vertical-align: middle; /* Aligns the radio button with the label text */
}

input[type="radio"]:checked {
  background-color: #0d9488;
  border: 2px solid #0d9488;
}

input[type="radio"]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

/* Ensure that radio buttons and text are on the same line */
.radio-label span {
  vertical-align: middle; /* Aligns the label text with the radio button */
}
