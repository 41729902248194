.not-pre-registration {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}

.pre-registration {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}

.rounded-chart-labels {
  border-radius: 10px !important; /* Set the border radius for the labels */
  background-color: rgba(
    54,
    162,
    235,
    0.2
  ); /* Set background color for labels */
  padding: 5px 10px; /* Adjust padding for the labels */
}

.chart-container {
  border-radius: 8px; /* Adjust the border radius as needed */

  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  overflow: hidden; /* Hide overflow if necessary */
  padding: 10px;
  background-color: white;
}

#inPatientGender {
  width: 100%; /* Make sure the canvas fills its container */
  height: auto;
}
