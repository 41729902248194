.inputs {
  width: 100%;
}

.footer {
  margin-top: 2em;
}

.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}

.cancel-btn:hover {
  background: lightgray !important;
}

.save-btn {
  margin-right: 1em;
}

.add-btn {
  margin-bottom: 1em;
}

.form-row {
  margin-top: 1em;
}

input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
  margin-left: 1em;
}

nz-select {
  margin-left: 1em;
}

label {
  font-weight: 500;
}

::ng-deep .ant-modal {
  min-width: 520px !important;
  width: 50% !important;
}

.reactions-container {
  margin-top: 2em;
  padding: 1.5em;
  border: 2px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}

.left-margin {
  margin-left: 1em;
}

.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}

nz-layout {
  background: #f4f4f6;
}

.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}

::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}

::ng-deep .ant-btn:focus {
  color: black;
}

::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}

::ng-deep .material-icons {
  font-size: 22px;
}

::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}

.title {
  padding-left: 1em;
  padding-right: 1em;
}
.add-button {
  margin-top: 1em;
  padding-right: 1em;
}

.table-header-row {
  height: 100%;
}

.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.no-known-content {
  display: flex !important;
}

.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}

::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}

::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}

::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}

.empty-table {
  margin-bottom: 3em;
}

.add-icon {
  margin-right: 0.2em;
}

@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}

.remove-btn {
  color: rgb(24, 146, 136) !important;
}

.remove-btn:hover {
  cursor: pointer;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

form {
  border: none !important;
}

.status-submitted {
  .status.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

.reactions-submitted {
  .status.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

::ng-deep .ant-modal-content {
  border-radius: 10px;
}

::ng-deep .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
}

::ng-deep nz-embed-empty {
  font-size: 16px !important;
  font-weight: 500 !important;
}

::ng-deep .ant-picker {
  margin-left: 1em !important;
}

.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
