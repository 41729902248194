.document-container.odd-line {
  background-color: #f9f9f9; /* Light color for odd lines */
}

.document-container.even-line {
  background-color: #e9e9e9; /* Slightly darker color for even lines */
}

.download:hover,
.delete-btn:hover {
  cursor: pointer;
}

.download,
.delete {
  margin-left: 0.5em;
}

.delete {
  background-color: red;
}

#inputFile::-webkit-file-upload-button {
  visibility: hidden;
}

.margin {
  margin: 15px;
}
