.add-patient-body {
  /* border: 1px solid green; */
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}

.add-patient-row {
  margin-bottom: 1.5em;
}

.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
  height: 35px !important;
}

.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}

.add-patient-inputZorro {
  width: 100%;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}

select.ng-valid.ng-touched {
  color: black;
}

input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

input[type="date"] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

::placeholder {
  color: #c2c2c2;
}

.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-patient-button:hover {
  cursor: pointer;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

.submitted {
  .ng-invalid {
    border: red 1px solid;
  }
}

.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.add-patient-button {
  margin-top: 1em;
  height: 35px;
  border: 1px solid #0d9488;
  border-radius: 4px;
  background-color: #0d9488 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button {
  margin-right: 1em;
  margin-bottom: 2em;
  height: 35px;
  border: 1px solid #ff2626;
  border-radius: 4px;
  background-color: white;
  color: #ff2626;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-patient-button:hover {
  cursor: pointer;
}

.back-button {
  cursor: pointer;
}

/*****************************************************
	Custom styling for intl tel input.
*****************************************************/

::ng-deep ngx-intl-tel-input input {
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

::ng-deep ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

::ng-deep ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

::ng-deep ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

::ng-deep ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

::ng-deep ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

::ng-deep .ant-select-selector {
  height: 100% !important;
}
