/* Container Styles */
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin: 24px 5px 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-actions {
  display: flex;
  gap: 8px; /* spacing between Previous and Next buttons */
}

.right-actions {
  margin-left: auto;
}

button {
  margin-right: 0; /* Remove margin-right to avoid extra spacing */
}

button {
  margin-right: 8px;
}
button:disabled {
  border: none !important; /* Remove the border from disabled buttons */
  background-color: #f5f5f5; /* Optional: Set a background color for disabled state */
  color: #bfbfbf; /* Optional: Set a text color for disabled state */
  cursor: not-allowed; /* Optional: Change the cursor to indicate disabled state */
}

button:disabled span {
  color: #bfbfbf !important; /* Ensure text color is consistent inside disabled buttons */
}

/* Customize the steps progress line */
::ng-deep .ant-steps-item-process .ant-steps-item-icon {
  background-color: #0d9488 !important;
  border-color: #0d9488 !important;
}

::ng-deep .ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: #0d9488 !important;
}

::ng-deep .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0d9488 !important;
  border-color: #0d9488 !important;
}

::ng-deep .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}

::ng-deep .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}

::ng-deep .ant-steps-item-icon > .ant-steps-icon {
  color: #0d9488 !important;
}

/* Customize the steps dots */
::ng-deep .ant-steps-dot .ant-steps-icon-dot {
  background-color: #0d9488 !important;
}

::ng-deep .ant-steps-dot .ant-steps-icon-dot:hover {
  background-color: #0a6f63 !important;
}

/* Customize the step title hover effect */
::ng-deep .ant-steps-item-title:hover {
  color: #0d9488 !important;
}

/* Customize the step title for the current step */
::ng-deep .ant-steps-item-process .ant-steps-item-title {
  color: #0d9488 !important;
}

form {
  background-color: white !important;
  padding: 50px;
}
.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}

.table-header-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-row {
  height: 100%;
}
.margin-lr {
  margin-left: 1em;
  margin-right: 1em;
}
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
