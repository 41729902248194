.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nz-table {
  width: 100%;
}
.ant-table-tbody > tr.submitted-item > td {
  border-left: 2px solid #0d9488;
}
::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {
  background-color: #0d9488 !important;
  border-color: #0d9488 !important;
}