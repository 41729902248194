.document-container.odd-line {
  background-color: #f9f9f9; /* Light color for odd lines */
}

.document-container.even-line {
  background-color: #e9e9e9; /* Slightly darker color for even lines */
}

.document-container {
  margin: 1em;
}

.document-container-top {
  margin-top: 0px !important;
}
