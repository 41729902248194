.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}

nz-layout.parent {
  background: #f4f4f6;
  height: 100%;
}

nz-content.parent {
  /* padding-left: 50px;
  padding-right: 50px; */
}

nz-layout.child {
  background: #f4f4f6;
}

nz-content.child {
  border: 1px solid #d8d8d8 !important;
  padding: 1em;
}

.etiology {
  /* border: 1px solid red; */
}

.content {
  width: 100%;
}

.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}

::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}

.title {
  padding-left: 1em;
  padding-right: 1em;
}

.add-button {
  margin-top: 1em;
  padding-right: 1em;
}

.table-header-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
