.header-body {
  height: 66px !important;
  width: 100vw;
  position: fixed;
  z-index: 1;
}

mat-toolbar {
  height: 66px;
}

.header-toolbar {
  overflow-y: hidden;
  overflow-x: auto;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px lightgrey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.5);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.5);
}

.header {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.navbar {
  width: 100%;
  margin-left: 3em;

  overflow-y: hidden;
  overflow-x: auto;
}

@media (max-width: 1140px) {
  .navbar {
    margin-left: 0em !important;
  }
}

.mat-toolbar {
  background-color: #161d20;
}

.mat-mdc-list-item-unscoped-content .mdc-list-item__primary-text {
  color: red !important;
}

.mat-mdc-list-item-unscoped-content .mdc-list-item__primary-text {
  width: auto;
}

.mdc-fab {
  scale: 0.65;
}

.header-logo {
  max-width: 144px;
  margin-left: 1em;
}

.header-logo:hover {
  cursor: pointer;
}

.header-search {
  background-color: transparent !important;
  color: #c2c2c2 !important;
  border: 1px solid #c2c2c2 !important;
  border-radius: 4px;
  height: 50%;
  width: 40%;
  padding-left: 0.5em;
}

.header-notifications {
  font-size: inherit !important;
  background-color: transparent;
}

.header-notifications-icon {
  scale: 1.55;
}

.header-profile {
  background-color: #0d9488;
}

.header-profile-initials {
  scale: 1.55;
}

.header-notifications,
.header-profile,
.header-search {
  /* margin-right: 0.5em; */
  font-size: 14px;
}

mat-nav-list {
  width: 100%;
}

mat-button-toggle {
  font-size: 14px;
  padding-right: 1em;
  background-color: transparent;
  color: white;
  border: 0px solid black !important;
  font-weight: 400;
}

mat-button-toggle-group {
  border: 0px solid black !important;
}

.mat-button-toggle-checked {
  background-color: transparent !important;
  color: #379f93 !important;
}

.header-language {
  font-size: 14px;
  background-color: transparent;
  color: white;
  border: 0px solid black !important;
}

.header-language:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0rem rgb(0, 0, 0) !important;
}

option {
  color: white;
  background-color: black;
}

::ng-deep.mat-mdc-menu-panel {
  margin-top: 5px !important;
  margin-right: 10px;
  padding: 10px 15px !important;
  background-color: #f4f4f6 !important;
}
::ng-deep.mat-mdc-menu-content {
}

::ng-deep mat-pseudo-checkbox {
  display: none !important;
}
