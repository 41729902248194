.add-patient-body {
  /* border: 1px solid green; */
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}

.add-patient-row {
  margin-bottom: 1.5em;
}

.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
  height: 35px !important;
}

.add-patient-input {
  color: #c2c2c2;
  /* border: 1px solid #d8d8d8; */
  border-radius: 4px;
  height: 100% !important;
  width: 100%;
  min-height: 35px !important;
}

select.ng-valid.ng-touched {
  color: black;
}

input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

input[type="date"] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

::placeholder {
  color: #c2c2c2;
}

.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-patient-button:hover {
  cursor: pointer;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

.submitted {
  .ng-invalid {
    border: red 1px solid;
    border-radius: 2px;
  }
}

.hio {
  margin-left: 1em;
}

::ng-deep nz-select-top-control {
  height: auto !important;
}
::ng-deep .ant-select-selector {
  height: 100% !important;
}
